import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdAssignmentTurnedIn } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About | FootBallTech
			</title>
			<meta name={"description"} content={"FootBallTech's essence: Authentic passion, premium selections"} />
			<meta property={"og:title"} content={"About | FootBallTech"} />
			<meta property={"og:description"} content={"FootBallTech's essence: Authentic passion, premium selections"} />
			<meta property={"og:image"} content={"https://footinv-uk.net/img/equip_6.jpg"} />
			<link rel={"shortcut icon"} href={"https://footinv-uk.net/img/icon37.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://footinv-uk.net/img/icon37.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://footinv-uk.net/img/icon37.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://footinv-uk.net/img/icon37.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://footinv-uk.net/img/icon37.png"} />
			<meta name={"msapplication-TileImage"} content={"https://footinv-uk.net/img/icon37.png"} />
			<meta name={"msapplication-TileColor"} content={"https://footinv-uk.net/img/icon37.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--green"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
			background="rgba(0, 135, 90, 0.99)"
		>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Your trusted online partner for all things football
			</Text>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				Learn more about us
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Since our inception, FootBallTech has been dedicated to elevating the football experience for enthusiasts around the globe. Our passion for the sport drives us to carefully curate a selection of equipment, apparel, and gear, bridging the gap between fans, players, and the brands they love.
At FootBallTech, it's not just about selling products — it's about fostering a community that breathes, lives, and celebrates football every day. We're your trusted partner on this journey, connecting you with the best the sport has to offer.
			</Text>
			<Box min-width="100px" min-height="100px">
				<Button
					type="link"
					font="--headline3"
					text-decoration-line="initial"
					color="--light"
					border-radius="8px"
					border-width="1px"
					border-style="solid"
					border-color="--color-light"
					background="rgba(0, 119, 204, 0)"
					href="/contact-us"
				>
					Visit us
				</Button>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Our Advantages
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Curated Selections
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					At FootBallTech, we don’t just stock items. We handpick every product, ensuring that our customers get access to only the best and most relevant football gear in the market.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Customer-First Approach
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Our dedication goes beyond sales. We pride ourselves on exceptional customer service, always aiming to provide prompt responses, easy returns, and a seamless shopping experience.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Authentic Brand Partnerships
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					We collaborate closely with top football brands globally. This guarantees our customers genuine products, backed by brand warranties and the assurance of authenticity.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Passionate Expertise
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Our team is composed of avid football fans and players. Their combined experience and love for the game mean they understand exactly what our customers seek, offering insights and recommendations that truly matter.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Competitive Pricing
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					By maintaining strong relationships with suppliers and minimising overheads, we ensure that our pricing remains competitive, giving our customers great value for their money.
				</Text>
			</Box>
			<Box
				margin="36px 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 36px 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Eco-Conscious Packaging
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					In our efforts to protect the planet, FootBallTech uses sustainable and minimal packaging materials, reducing environmental impact without compromising on product protection.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://footinv-uk.net/img/equip_6.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://footinv-uk.net/img/equip_3.jpg"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://footinv-uk.net/img/equip_7.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://footinv-uk.net/img/net_1.jpg"
							position="absolute"
							width="100%"
							left={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://footinv-uk.net/img/equip_5.jpg"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					When you choose FootBallTech, you're not just choosing a retailer — you're selecting a partner that understands your love for the game and is committed to enriching your football journey. Join us, and experience the FootBallTech difference.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});